import React, {Component} from 'react';

import Card from '../Card/Card';
import PijlRechts from '../Icons/PijlRechts';

import './Frontpage.scss';

class Frontpage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
		const { page, language, handleOpenPage } = this.props;

		const textButtonPre =
			language === "nl"
				? "Wel of geen vruchtbaarheidsbehoud?"
				: "Fertility preservation or not?";

		const { text, title, cards } = page[language];

		const showCards = cards.map(card => (
			<Card
				key={card.id}
				id={card.id}
				title={card.title}
				text={card.text}
				link={card.link}
				handleClickCard={handleOpenPage}
			/>
		));

		return (
			<div className="page frontpage">
			  <div className="intro">
			  	<p className="intro-text">{text}</p>
			  	<div className="intro-buttons">
				 <button id="voortraject" onClick={() => handleOpenPage(1)}>
				  	{textButtonPre}
				  	<PijlRechts />
				 </button>
			  	</div>
			  </div>
			  <h3 className="page-title">{title}</h3>
			  <div className="page-cards">{showCards}</div>
		  </div>
		)
	}
}

export default Frontpage;
