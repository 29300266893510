import React from 'react';

import Menu from '..//Menu/Menu';
import Pijl from '../Icons/Pijl';
import Hamburger from '../Icons/Hamburger';

import './Header.scss';

const Header = (props) => {
	const {
		language,
		//handleClickBack,
		handleOpenPage,
		//handleClickHamburger,
		//items,
		//status,
		//handleClickMenu,
		//showBackButton
	} = props;

		const titleClass = props.showBackButton ? "header-title" : "header-title noback";
		const backbuttonClass = props.showBackButton ? "backbutton" : "backbutton hide";
		const title =
		language === "nl"
			? "Keuzehulp vruchtbaarheidsbehoud transmannen"
			: "Decision Aid Fertility Preservation for Trans Men";

		return (
		  <div className="header">
		  	<button className={backbuttonClass} onClick={props.handleClickBack}><Pijl /></button>
		   	<h1 className={titleClass} onClick={() => handleOpenPage(0)}>
				{title}
			</h1>
		    <button
		    	className="hamburgerbutton"
		    	onClick={props.handleClickHamburger}>
		    	<Hamburger />
	    	</button>
		    <Menu
	          items={props.items}
	          status={props.status}
	          handleClickMenu={props.handleClickMenu}
	          language={language}
        />
		  </div>
		)
}

export default Header;
