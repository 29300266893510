import React from 'react';

import './Menu.scss';

const Menu = props => {

	const { language, handleClickMenu, status } = props;

	const items = props.items.map((item) => (
		<li key={item.id}>
			<a href={"#"+item.id} id={item.id} onClick={handleClickMenu}>
				{item.name[language]}
			</a>
		</li>
		))

  return (
        <div className={"menu " + status} >
        	<ul>
        		{items}
        	</ul>
        </div>
    );
}

export default Menu;
