const data = {
	text:
		"Wat zijn voor mij argumenten voor en tegen een behandeling voor vruchtbaarheidsbehoud?",
	items: [
		{
			text: "Resultaat",
			items: [
				{
					text: "Argumenten voor",
					items: [
						{
							text:
								"Ik wil geen risico lopen op mogelijke onvruchtbaarheid door langdurig hormoongebruik",
							items: [
								{
									text:
										"Het langetermijneffect van testosteron op mijn eierstokken is nog onduidelijk."
								}
							]
						},
						{
							text:
								"Mijn partner wil dat ik nu een behandeling voor vruchtbaarheidsbehoud onderga",
							items: [
								{
									text:
										"Mijn partner wil niet dat ik risico's loop door het mogelijke effect van testosteron op mijn eierstokken."
								}
							]
						}
					]
				},
				{
					text: "Argumenten tegen",
					items: [
						{
							text:
								"Ik hoef nu geen keuze te maken over kinderen",
							items: [
								{
									text:
										"Door mijn eierstokken te behouden heb ik langer de tijd om te besluiten of ik wel of geen kinderen wil."
								},
								{
									text:
										"Ik vind mezelf te jong om nu al te beslissen of ik wel of geen kinderen wil."
								}
							]
						},
						{
							text: "Ik kan ook zonder behandeling voor vruchtbaarheidsbehoud een kind krijgen",
							items: [
								{
									text:
										"Ik kan als ik mijn eierstokken laat verwijderen ook op andere manieren kinderen krijgen, zoals door eiceldonatie of adoptie."
								}
							]
						},
						{
							text:
								"Ik hoef nu geen behandeling voor vruchtbaarheidsbehoud te ondergaan om een genetisch eigen kind te kunnen krijgen",
							items: [
								{
									text:
										"Als ik mijn eierstokken behoud kan ik later (meerdere keren) eicellen laten invriezen."
								}
							]
						}
					]
				}
			]
		},
		{
			text: "Transitie",
			items: [
				{
					text: "Argumenten voor",
					items: [
						{
							text: "Ik wil verder met mijn transitie",
							items: [
								{
									text:
										"Ik wil nu een vruchtbaarheidsbehandeling ondergaan, dan kan ik daarna mijn vrouwelijke organen laten verwijderen."
								},
								{
									text:
										"Ik wil nu een vruchtbaarheidsbehandeling ondergaan zodat ik later niet alsnog vrouwelijke hormonen hoef toe te dienen."
								},
								{
									text:
										"Ik wil nu een vruchtbaarheidsbehandeling ondergaan zodat ik daarna een genitale operatie kan ondergaan."
								}
							]
						}
					]
				},
				{
					text: "Argumenten tegen",
					items: [
						{
							text:
								"Ik kan zonder behandeling mijn transitie voortzetten zonder risico's voor mijn vruchtbaarheid",
							items: [
								{
									text:
										"Ik hoef niet te wachten tot de vruchtbaarheidsbehandeling afgelopen is voor ik met testosteron kan beginnen. "
								},
								{
									text:
										"Voor een vruchtbaarheidsbehandeling moet ik vrouwelijke hormonen toedienen, dit wil ik nu niet."
								},
								{
									text:
										"Ik kan direct een genitale operatie ondergaan, mijn baarmoeder en vagina kunnen worden verwijderd."
								},
								{
									text:
										"Ik kan mijn eierstokken laten zitten, omdat ik ze niet zie heb ik er geen psychische last van."
								}
							]
						}
					]
				}
			]
		},
		{
			text: "Kosten",
			items: [
				{
					text: "Argumenten tegen",
					items: [
						{
							text:
								"Een behandeling voor vruchtbaarheidsbehoud kost de maatschappij geld",
							items: [
								{
									text:
										"Mijn eierstokken laten zitten en geen behandeling ondergaan kost mij en de maatschappij geen geld."
								}
							]
						}
					]
				}
			]
		},
		{
			text: "Gezondheid",
			items: [
				{
					text: "Argumenten voor",
					items: [
						{
							text:
								"Een behandeling voor vruchtbaarheidsbehoud ondergaan levert me mogelijk minder stress op",
							items: [
								{
									text:
										"Het is psychisch zwaar om bezig te zijn met een kinderwens, ik wil dit onderwerp zo snel mogelijk afsluiten."
								}
							]
						}
					]
				},
				{
					text: "Argumenten tegen",
					items: [
						{
							text:
								"Ik vermijd de gezondheidsrisico's van een behandeling voor vruchtbaarheidsbehoud",
							items: [
								{
									text:
										"Ik hoef geen vruchtbaarheidsbehandeling in het ziekenhuis te ondergaan."
								}
							]
						}
					]
				}
			]
		},
		{
			text: "Gevoel",
			items: [
				{
					text: "Argumenten voor",
					items: [
						{
							text:
								"Een behandeling voor vruchtbaarheidsbehoud geeft me rust en een gevoel van zekerheid",
							items: [
								{
									text:
										"Het geeft me rust om te weten dat ik direct alles heb gedaan om mijn vruchtbaarheid te behouden."
								},
								{
									text:
										"Ik wil niet het risico lopen spijt te krijgen als later blijkt dat testosteron schadelijk is voor mijn eierstokken."
								}
							]
						},
						{
							text:
								"Een behandeling voor vruchtbaarheidsbehoud geeft me een gevoel van zelfstandigheid",
							items: [
								{
									text:
										"Ik wil zoveel mogelijk regie over mijn zorg houden."
								}
							]
						}
					]
				},
				{
					text: "Argumenten tegen",
					items: [
						{
							text:
								"Afwachten geeft me een gevoel van zelfstandigheid",
							items: [
								{
									text:
										"Ik wil zoveel mogelijk regie over mijn zorg houden."
								}
							]
						},
						{
							text:
								"Een behandeling voor vruchtbaarheidsbehoud past op dit moment niet bij mijn leven",
							items: [
								{
									text:
										"Ik wil me voorlopig alleen richten op mijn transitie."
								}
							]
						},
						{
							text:
								"Ik wil geen behandeling voor vruchtbaarheidsbehoud uit persoonlijke overtuiging",
							items: [
								{ text: "Ik wil geen kinderen." },
								{ text: null }
							]
						}
					]
				}
			]
		}
	]
};

export default data
