import React, {Component} from 'react';

import Card from '../Card/Card';

import './Cardpage.scss';

class Cardpage extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

	render(){

		const { page, language, handleClickCard } = this.props;
		const { title, cards } = page[language];

		const showCards = cards.map(card => <Card
						key={card.id}
						id={card.id}
						title={card.title}
						text={card.text}
						link={card.link}
						handleClickCard={handleClickCard}
						/>)

		return (
			<div className="page cardpage">
			  <h3 className="page-title">{title}</h3>
			  <div className="page-cards">{showCards}</div>
		  </div>
		)
	}
}

export default Cardpage;
