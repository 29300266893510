import React from 'react';

import Kruis from '../Icons/Kruis';

import './Modal.scss';

const Modal = (props) => {

  const { status, language, handleClickModal, content  } = props;
  const { title, text } = content[language];

  return (
      <div className={"modal " + status}>
        <div className="modal-overlay" onClick={handleClickModal}></div>
        <div className="modal-popup">
          <h4 className="modal-title">
            {title}
            <button className="modal-close" onClick={handleClickModal}>
              <Kruis />
            </button>
          </h4>
          <div className="modal-content">{text}</div>
        </div>
       </div>
    );
}

export default Modal;
