import React from 'react';

import harmonica1 from './harmonica-1-opereren.js';
import harmonica2 from './harmonica-2-eicellen.js';
import harmonica3 from './harmonica-3-embryos.js';
import harmonica4 from './harmonica-4-eierstokweefsel.js';

import geslachtsorgaanBlauw from './geslachtsorgaanBlauw.svg';
import geslachtsorgaanGroen from './geslachtsorgaanGroen.svg';
import geslachtsorgaanOranje from './geslachtsorgaanOranje.svg';
import geslachtsorgaanBlauwEn from './geslachtsorgaanBlauw-en.svg';
import geslachtsorgaanGroenEn from './geslachtsorgaanGroen-en.svg';
import geslachtsorgaanOranjeEn from './geslachtsorgaanOranje-en.svg';

import harmonica1en from './harmonica-1-opereren-en.js';
import harmonica2en from './harmonica-2-eicellen-en.js';
import harmonica3en from './harmonica-3-embryos-en.js';
import harmonica4en from './harmonica-4-eierstokweefsel-en.js';

export const pages = [{
    id: 0,
    template: 'frontpage',
    nl: { 
    text: 'Deze Keuzehulp helpt bij het maken van keuzes over vruchtbaarheidsbehoud. De eerste keuze is of je een behandeling voor vruchtbaarheidsbehoud wil ondergaan. Je kan deze keuze ook uitstellen, dan kan je je eierstokken voorlopig laten zitten. Je baarmoeder kan je eventueel wel laten verwijderen. De verschillende opties voor vruchtbaarheidsbehandelingen en belangrijkste argumenten staan overzichtelijk geordend. Deze Keuzehulp helpt bij het gesprek met arts of betrokkenen maar is géén instrument waaruit een beslissing volgt.',
    title: 'Wat zijn voor mij opties voor vruchtbaarheidsbehoud?',
    cards: [
        { id: 'card1', title: 'Eicellen invriezen', text: '', link: 2 },
        { id: 'card2', title: 'Embryo\'s invriezen', text: '', link: 3 },
        { id: 'card3', title: 'Eierstokweefsel invriezen', text: '', link: 4 }
    ]
}, 
en: {
    text: 'This Decision Aid can be used as a tool to make informed decisions regarding fertility preservation. The first choice you have to make is whether you are willing to undergo fertility preservation treatment. You can also postpone this choice and retain your ovaries for the time being. If you wish, you can have your uterus removed. We have listed the different options for fertility preservation and the most important arguments. This Decision Aid offers support when discussing fertility preservation with a doctor or other parties involved in the process. It is not a tool that provides a decision.',
    title: 'What options for fertility preservation are there for me?',
    cards: [
        { id: 'card1', title: 'Freezing eggs', text: '', link: 2 },
        { id: 'card2', title: 'Freezing embryos', text: '', link: 3 },
        { id: 'card3', title: 'Freezing ovarian tissue', text: '', link: 4 }
    ]
}
}, {
    id: 1,
    slug: 'operaties',
    template: 'infopage',
    intro:  {
        nl: {
        expandable: true,
        title: 'Toelichting',
        content:
            <div className="panel-box">
                <p>De keuze voor een behandeling voor vruchtbaarheidsbehoud is een optie, het is geen medische noodzaak. Het hangt van de individuele voorkeur, medische conditie en situatie af of je hiervoor wil kiezen. De argumenten hieronder gaan over het moment van een mogelijke vruchtbaarheidsbehandeling. Als je ervoor kiest om nu geen behandeling voor vruchtbaarheidsbehoud te ondergaan maar wel je eierstokken te behouden kan je later alsnog voor een behandeling kiezen. Als je ervoor kiest om je eierstokken te laten verwijderen moet je een behandeling voor vruchtbaarheidsbehoud ondergaan om nog kans te houden op een genetisch eigen kind.</p>
            </div>
    },
    en :{
        expandable: true,
        title: 'Explanation',
        content:
            <div className="panel-box">
                <p>Undergoing fertility preservation treatment is an option; it is not a medical necessity. The decision whether to undergo treatment for fertility preservation depends on your individual preferences, medical condition and situation. The arguments below should be considered when making choices about fertility preservation. If you choose not to undergo fertility preservation treatment now but to retain your ovaries, you can still opt for fertility preservation at a later date. If you choose to have your ovaries removed, you will need to undergo fertility preservation treatment in order to have a chance of having your own genetically-related children in the future.</p>
            </div>        
        },
    },
    voorwaarden: {
        nl: {
        expandable: true,
        title: 'Wat houdt geen behandeling voor vruchtbaarheidsbehoud in?',
        content:
        <div className="panel-box">
            <ul>
                <li>Je laat je eierstokken niet verwijderen, je kan als je dat wil je baarmoeder ook behouden.</li>
                <li>Je kan starten met testosteron.</li>
                <li>Je kan later alsnog eicellen uit je lichaam laten halen, zie de optie Eicellen invriezen.</li>
                <li>Je kan je eierstokken later alsnog laten verwijderen, als je je baarmoeder al hebt laten verwijderen betekent dat een extra operatie.</li>
                <li>Met behoud van je eierstokken en baarmoeder heb je de mogelijkheid om zwanger te worden.</li>
            </ul>
        </div>
    },
        en: {
        expandable: true,
        title: 'What does the option ‘no fertility preservation treatment’ entail?',
        content:
        <div className="panel-box">
            <ul>
                <li>Your ovaries are not removed. If you wish, you can also retain your uterus.</li>
                <li>You can start with testosterone.</li>
                <li>You can have your eggs retrieved at a later date, see option: Freezing eggs.</li>
                <li>You can still have your ovaries removed at a later date. If your uterus has already been removed, this means an additional operation.</li>
                <li>If you choose to retain your ovaries and uterus it is possible to get pregnant.</li>
            </ul>
        </div>
        }
    },
    harmonica: {
        nl: harmonica1,
        en: harmonica1en,
    },
    expandables: [0,1,2],
    closed: [0,1,2]
},{
    id: 2,
    slug: 'eicellen',
    template: 'infopage',
    intro: {
        nl: {
        expandable: true,
        title: 'Wat houdt het invriezen van eicellen in?',
        content:
        <div className="panel-box">
            <img src={geslachtsorgaanOranje} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
            <ul>
                <li>Je moet ongeveer drie maanden voor de behandeling stoppen met testosteron.</li>
                <li>Je moet jezelf twee tot drie weken onderhuids hormonen inspuiten om eiblaasjes te laten groeien.</li>
                <li>Een arts of verpleegkundige maakt meerdere vaginale echo's om te kijken of de eiblaasjes goed groeien.</li>
                <li>Als de eiblaasjes groot genoeg zijn haalt een arts ze met een naald via je vagina uit de eierstok.</li>
                <li>Tijdens de ingreep ben je wel wakker, maar vooraf krijg je een kalmerend en pijnstillend middel.</li>
                <li>Alleen de rijpe eicellen worden ingevroren.</li>
                <li>Zodra je een kinderwens hebt kunnen de eicellen worden ontdooid.</li>
                <li>De eicellen worden bevrucht in het laboratorium met zaadcellen van je mannelijke partner of een donor.</li>
                <li>De embryo's worden bij een draagmoeder of bij jezelf geplaatst.</li>
            </ul>
        </div>
    },
    en :{
        expandable: true,
        title: 'What does egg freezing involve?',
        content:
        <div className="panel-box">
            <img src={geslachtsorgaanOranjeEn} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
            <ul>
                <li>You need to stop taking testosterone approximately three months before the treatment process.</li>
                <li>You need to self-administer hormone injections under the skin for two to three weeks to stimulate the follicles to grow.</li>
                <li>A doctor or nurse will perform a number of transvaginal ultrasounds to assess whether the follicles are developing properly.</li>
                <li>Once the follicles are a good size, a doctor will remove the eggs from your ovaries via the insertion of a needle through your vaginal wall.</li>
                <li>During the procedure you will be awake, but you will be given medication for pain relief and sedation beforehand.</li>
                <li>Only the mature eggs are frozen.</li>
                <li>When you are ready to have children, your eggs can be thawed.</li>
                <li>In the laboratory, sperm of your male partner or a donor is injected into the eggs to fertilise them.</li>
                <li>The embryos are then transferred to your womb or the womb of a surrogate mother.</li>
            </ul>
        </div>      
        },
    },
    voorwaarden: {
        nl: {
        expandable: true,
        title: 'Wat zijn de voorwaarden voor het invriezen van eicellen?',
        content:
        <div className="panel-box">
            <ul>
                <li>Je moet lichamelijk en psychisch in staat zijn om meerdere vaginale echo's en een punctie te ondergaan.</li>
                <li>Je mag voor de behandeling niet ouder zijn dan 40 jaar.</li>
                <li>De behandeling is bespreekbaar vanaf 16 jaar.</li>
                <li>Je behandelteam moet het draagmoederschap goedkeuren voor er een embryo kan worden geplaatst.</li>
            </ul>
        </div>
    },
        en: {
        expandable: true,
        title: 'What are the conditions for the freezing of eggs?',
        content:
        <div className="panel-box">
            <ul>
                <li>You must be physically and psychologically capable of undergoing multiple transvaginal ultrasounds and an egg retrieval procedure.</li>
                <li>The age limit for egg freezing is 40 years.</li>
                <li>The possibility of egg freezing is open to discussion from the age of 16.</li>
                <li>Your medical team must approve the surrogacy before an embryo can be transferred.</li>
            </ul>
        </div>
        }
    },
    harmonica: {
        nl: harmonica2,
        en: harmonica2en,
    },
    expandables: [0,1,2],
    closed: [0,1,2]
},{
    id: 3,
    slug: 'embryos',
    template: 'infopage',
    intro:  {
        nl: {
        expandable: true,
        title: 'Wat houdt het invriezen van embryo\'s in?',
        content:
        <div className="panel-box">
            <img src={geslachtsorgaanBlauw} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
            <ul>
                <li>Je moet ongeveer drie maanden voor de behandeling stoppen met testosteron.</li>
                <li>Je moet jezelf twee tot drie weken onderhuids hormonen inspuiten om eiblaasjes te laten groeien.</li>
                <li>Een arts of verpleegkundige maakt meerdere vaginale echo's om te kijken of de eiblaasjes goed groeien.</li>
                <li>Als de eiblaasjes groot genoeg zijn haalt een arts ze met een naald via je vagina uit de eierstok.</li>
                <li>Tijdens de ingreep ben je wel wakker, maar vooraf krijg je een kalmerend en pijnstillend middel.</li>
                <li>Alleen de rijpe eicellen worden bevrucht in het laboratorium, waarna de embryo's worden ingevroren.</li>
                <li>De embryo's worden bij een draagmoeder of bij jezelf geplaatst.</li>
            </ul>
        </div>
    },
    en :{
        expandable: true,
        title: 'What does embryo freezing involve?',
        content:
        <div className="panel-box">
            <img src={geslachtsorgaanBlauwEn} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
            <ul>
                <li>You need to stop taking testosterone approximately three months before the treatment process.</li>
                <li>You need to self-administer hormone injections under the skin for two to three weeks to stimulate the follicles to grow.</li>
                <li>A doctor or nurse will perform a number of transvaginal ultrasounds to assess whether the follicles are developing properly.</li>
                <li>Once the follicles are a good size, a doctor will remove the eggs from your ovaries via the insertion of a needle through your vaginal wall.</li>
                <li>During the procedure you will be awake, but you will be given medication for pain relief and sedation beforehand.</li>
                <li>Only the mature eggs are fertilised in the laboratory, after which the embryos are frozen.</li>
                <li>The embryos are then transferred to your womb or the womb of a surrogate mother.</li>
            </ul>
        </div>      
        },
    },
    voorwaarden: {
        nl: {
        expandable: true,
        title: 'Wat zijn de voorwaarden voor het invriezen van embryo\'s?',
        content:
        <div className="panel-box">
            <ul>
                <li>Je moet lichamelijk en psychisch in staat zijn om meerdere vaginale echo's te ondergaan.</li>
                <li>Je moet een mannelijke partner of donor hebben die toestemt met de behandeling en het plaatsen van de embryo's.</li>
                <li>Je mag niet ouder zijn dan 40 jaar.</li>
                <li>Je behandelteam moet het draagmoederschap goedkeuren voor er een embryo kan worden geplaatst.</li>
            </ul>
        </div>
    },
        en: {
        expandable: true,
        title: 'What are the conditions for the freezing of embryos?',
        content:
        <div className="panel-box">
            <ul>
                <li>You must be physically and psychologically capable of having multiple transvaginal ultrasounds.</li>
                <li>You have to have a male partner or donor who consents to the treatment and embryo transferral.</li>
                <li>The age limit for embryo freezing is 40 years.</li>
                <li>Your medical team must approve the surrogacy before an embryo can be transferred.</li>
            </ul>
        </div>
        }
    },
    harmonica: {
        nl: harmonica3,
        en: harmonica3en,
    },
    expandables: [0,1,2],
    closed: [0,1,2]
},{
    id: 4,
    slug: 'weefsel',
    template: 'infopage',
    intro:  {
        nl: {
        expandable: true,
        title: 'Wat houdt het invriezen van eierstokweefsel in?',
        content:
        <div className="panel-box">
            <img src={geslachtsorgaanGroen} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
            <p>Deze behandeling is experimenteel en bevindt zich nog in de onderzoeksfase.</p>
            <ul>
                <li>Met een kijkoperatie verwijdert een arts een van je eierstokken.</li>
                <li>De buitenste laag van de eierstok bevat onrijpe eicellen, deze laag wordt in delen ingevroren.</li>
                <li>Met een kijkoperatie plaatst een arts stukjes ontdooid eierstokweefsel op de achtergebleven eierstok of een andere plek in je buikholte.</li>
                <li>Het is in de toekomst wellicht mogelijk om eicellen te verkrijgen uit het verkregen weefsel in het laboratorium.</li>
                <li>Het eierstokweefsel kan niet bij een ander worden teruggeplaatst, met eicellen zou dit wel kunnen.</li>
            </ul>
        </div>
    },
    en :{
        expandable: true,
        title: 'What does ovarian tissue freezing involve?',
        content:
        <div className="panel-box">
            <img src={geslachtsorgaanGroenEn} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
            <ul>
                <li>This process is currently experimental and still in a research phase.</li>
                <li>It involves taking one of your ovaries out of the body by means of keyhole surgery.</li>
                <li>The outer layer of the ovary, which contains immature eggs, is frozen in parts.</li>
                <li>By means of keyhole surgery, the ovarian strips are thawed and transplanted back into the body, either on the remaining ovary or elsewhere in your abdomen.</li>
                <li>In the future, it may be possible to retrieve eggs from the tissue in the laboratory.</li>
                <li>Only the mature eggs are fertilised in the laboratory, after which the embryos are frozen.</li>
                <li>Ovarian tissue, unlike eggs, cannot be implanted in another person’s body.</li>
            </ul>
        </div>      
        },
    },
    voorwaarden: {
        nl: {
        expandable: true,
        title: 'Wat zijn de voorwaarden voor het invriezen van eierstokweefsel?',
        content:
        <div className="panel-box">
            <ul>
                <li>Je komt alleen voor deze behandeling in aanmerking als je ook een genitale operatie ondergaat.</li>
            </ul>
        </div>
    },
        en: {
        expandable: true,
        title: 'What are the conditions for the freezing of ovarian tissue?',
        content:
        <div className="panel-box">
            <ul>
                <li>You are only eligible for this treatment if you also undergo genital surgery.</li>
            </ul>
        </div>
        }
    },
    harmonica: {
        nl: harmonica4,
        en: harmonica4en,
    },
    expandables: [0,1,2],
    closed: [0,1,2]
}
];
