const data = {"text": "Wat zijn voor mij argumenten voor en tegen eierstokweefsel invriezen?","items": [{
    "text": "Resultaat",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling geeft me de kans een genetisch eigen kind te krijgen",
            "items": [{
                "text": "Ik vergroot mijn kans op een kind."
            }, {
                "text": "Ik kan potentieel met het eierstokweefsel meer eicellen invriezen dan als ze via een punctie worden verkregen."
            }]
        }, {
            "text": "De behandeling kan meer opleveren dan we nu weten  ",
            "items": [{
                "text": "Als de techniek zich ontwikkelt zijn er mogelijk manieren om mijn eicellen te laten rijpen buiten mijn lichaam."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling is experimenteel en het resultaat onzeker",
            "items": [{
                "text": "Het is onzeker of ik met deze behandeling zwanger kan worden."
            }, {
                "text": "Het is onzeker of ik het weefsel kan gebruiken omdat de behandeling nog niet grootschalig wordt toegepast."
            }, {
                "text": "Het rijpen van eicellen in het laboratorium is nu nog niet mogelijk."
            }, {
                "text": "De kans op succes is op dit moment kleiner dan bij eicellen en embryo's invriezen."
            }]
        }]
    }]
}, {
    "text": "Gezondheid",
    "items": [{
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling kan mijn gezondheid en die van mijn kind schaden",
            "items": [{
                "text": "Ik vind de gezondheidsrisico's van de operatie te hoog."
            }, {
                "text": "Er is weinig bekend over de effecten van de behandeling op de gezondheid van het kind. "
            }]
        }]
    }]
}, {
    "text": "Transitie",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling verstoort mijn transitie niet",
            "items": [{
                "text": "Ik hoef niet te stoppen met testosteron."
            }, {
                "text": "Ik hoef geen vrouwelijke hormonen toe te dienen."
            }]
        }, {
            "text": "Ik kan mijn transitie voortzetten zonder dat mijn eicellen schade oplopen",
            "items": [{
                "text": "Mijn eicellen kunnen geen schade meer oplopen door langdurig testosterongebruik."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "Mijn transitie wordt verstoord als het weefsel wordt teruggeplaatst",
            "items": [{
                "text": "Als het weefsel wordt teruggeplaatst moet ik stoppen met testosteron en vrouwelijke hormonen innemen."
            }]
        }]
    }]
}, {
    "text": "Behandeling",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling past bij mijn situatie op dit moment",
            "items": [{
                "text": "Ik kan de operatie combineren met mijn buikoperatie."
            }, {
                "text": "Ik ben na de operatie direct klaar met de behandeling voor vruchtbaarheidsbehoud.."
            }, {
                "text": "Ik heb nu geen mannelijke partner of donor nodig."
            }]
        }, {
            "text": "De behandeling is lichamelijk en psychisch minder belastend dan eicellen of embryo's invriezen",
            "items": [{
                "text": "Deze behandeling is psychisch minder ingrijpend dan via punctie verkregen eicellen of embryo's invriezen."
            }, {
                "text": "Ik hoef geen vaginale echo's en punctie te ondergaan."
            }]
        }]
    }]
}, {
    "text": "Kosten",
    "items": [{
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling kost geld",
            "items": [{
                "text": "Ik moet mogelijk meebetalen voor het invriezen en opslaan van het weefsel."
            }, {
                "text": "Ziekenhuiszorg gaat af van het wettelijke eigen risico en mogelijk geldt een eigen bijdrage voor medicatie. "
            }]
        }]
    }]
}, {
    "text": "Overtuiging",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling past bij mijn levensovertuiging",
            "items": [{
                "text": "Ik mag van mijn religie geen embryo's invriezen, maar wel eierstokweefsel."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "Ik wil geen operatie ondergaan",
            "items": [{
                "text": "Ik ben bang voor operaties en/of narcose."
            }, {
                "text": "Ik wil geen operatie als het niet per se hoeft."
            }]
        }]
    }]
}]}

export default data
