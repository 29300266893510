import React from 'react';

const LogoFabriek = () => (
	<svg viewBox="0 0 500 100" className="logo fabriek">
		<g className="beeldmerk" transform="matrix(1.1728214,0,0,1.1728214,0,-123.00788)">
			<rect fill="#009FE3" x="26.6" y="131.5" width="80.9" height="58.5"/>
			<circle fill="#333" cx="26.6" cy="131.5" r="26.6"/>
			<circle fill="#fff" cx="26.6" cy="131.5" r="13.3"/>
		</g>
		<g transform="matrix(1.1728214,0,0,1.1728214,0,-123.00788)">
			<path fill="#fff" d="M75.3,175H65l-1.5,6.7h-9.6l10-40.1h12.6l9.9,40.1h-9.8C76.6,181.7,75.3,175,75.3,175z M70,150.6l-3.6,17.2h7.2L70,150.6L70,150.6z"/>
		</g>
		<g className="tekst" transform="matrix(1.1669263,0,0,1.1669263,-23.369688,-118.19021)">
			<path d="M140,141.1c3.1,0,6.3-0.1,9.3-0.1c10.1,0,16,3.4,16,17.9c0,12.5-5.7,17.7-16.9,17.7 c-3.1,0-5.9,0-8.4-0.1L140,141.1L140,141.1z M149.1,170.6c7.3,0,8.7-3.9,8.7-11.7c0-8.1-1.1-12.1-8.7-12.1c-0.8,0-1.4,0-2.1,0v23.5 C147.8,170.6,148.4,170.6,149.1,170.6z"/>
			<path d="M179.7,149c4.7,0,10.1,2.8,10.1,12.2c0,1.4-0.1,2.3-0.1,4h-14.1c0,4,1.1,6,4.5,6 c1.8,0,5.1-0.5,8.3-1.4l1.4,4.8c-3.9,1.6-7.1,2.4-10.6,2.4c-7.3,0-10.7-3.9-10.7-13.8C168.5,152.8,172.9,149,179.7,149L179.7,149z  M175.6,159.8L175.6,159.8l7.3,0.1v-0.1c0-2.8-0.9-5.3-3.6-5.3C176.6,154.5,175.6,156.9,175.6,159.8z"/>
			<path d="M211.9,170.1l-1.5,6.3H203l9.2-35.5h9.9l9.1,35.5h-7.7l-1.5-6.3H211.9z M216.9,147.7l-3.8,16.6 h7.5L216.9,147.7L216.9,147.7z"/>
			<path d="M240.9,176.4H234v-27.1h5.7l0.6,3.3c3-3,4.4-3.8,6.2-3.8h1.1v7.2h-1.4c-2.4,0-3.9,0.6-5.3,1.8 L240.9,176.4L240.9,176.4L240.9,176.4z"/>
			<path d="M260.5,186.2c-2,0-3.9-0.1-6.9-0.5l-1-5.4h6.7c5.1,0,5.4-2.4,5.4-5.4v-2.3c-2.8,2.1-4.7,3-6.9,3 c-5.4,0-8.3-4.9-8.3-13c0-8.7,3.6-13.7,9.1-13.7c2.1,0,4,0.9,6.7,3.1l0.6-2.9h5.7v25.7C271.6,183.4,267.3,186.2,260.5,186.2 L260.5,186.2z M256.6,162.3c0,5.3,1.1,7.2,3.4,7.2c1.5,0,3-0.9,4.4-2.3V157c-1.4-1.4-2.5-2.1-4-2.1 C258.1,155.2,256.6,156.9,256.6,162.3L256.6,162.3z"/>
			<path d="M286.6,170.8c1.5,0,2.9-0.9,4.5-2.3v-19h6.9v27h-5.7l-0.6-3c-3.1,2.5-5.3,3.3-7.7,3.3 c-4.7,0-7.1-3.4-7.1-8.7v-18.6h6.9v16C284,169.4,284.8,170.8,286.6,170.8L286.6,170.8z"/>
			<path d="M338.4,157.7v18.8h-6.9v-16c0-4.2-0.8-5.4-2.8-5.4c-1.5,0-2.9,0.8-4.4,2.3v19.1h-6.9v-16 c0-4.2-0.8-5.4-2.8-5.4c-1.5,0-2.9,0.8-4.4,2.3v19.1h-6.9v-27h5.7l0.6,3c3.1-2.5,5.3-3.3,7.5-3.3c2.9,0,4.9,1.1,5.9,3.6 c3.6-2.8,5.5-3.6,7.9-3.6C336.1,149,338.4,152.4,338.4,157.7L338.4,157.7z"/>
			<path d="M353.7,149c4.7,0,10.1,2.8,10.1,12.2c0,1.4-0.1,2.3-0.1,4h-14.1c0,4,1.1,6,4.5,6 c1.8,0,5.1-0.5,8.3-1.4l1.4,4.8c-3.9,1.6-7.1,2.4-10.6,2.4c-7.3,0-10.7-3.9-10.7-13.8C342.6,152.8,346.7,149,353.7,149L353.7,149z  M349.6,159.8L349.6,159.8l7.3,0.1v-0.1c0-2.8-0.9-5.3-3.6-5.3S349.6,156.9,349.6,159.8L349.6,159.8z"/>
			<path d="M379.7,155.2c-1.5,0-2.9,0.8-4.4,2.3v19h-6.9v-27h5.7l0.6,3c3.1-2.4,5.3-3.3,7.7-3.3 c4.7,0,7.1,3.4,7.1,8.7v18.8h-6.9v-16.1C382.4,156.5,381.6,155.2,379.7,155.2L379.7,155.2z"/>
			<path d="M394.8,169.7v-14.9h-3.1v-5.4h3.1v-7.2l6.9-0.9v8.4h5.1v5.4h-5.1v13.7c0,2.4,0.6,3,4.7,2.3 l0.6,5.3c-2.1,0.5-4.4,0.9-5.5,0.9C398.1,176.9,394.8,175.7,394.8,169.7z"/>
			<path d="M420.1,149c4.7,0,10.1,2.8,10.1,12.2c0,1.4-0.1,2.3-0.1,4H416c0,4,1.1,6,4.5,6 c1.8,0,5.1-0.5,8.3-1.4l1.4,4.8c-3.9,1.6-7.1,2.4-10.6,2.4c-7.3,0-10.7-3.9-10.7-13.8C409,152.8,413.2,149,420.1,149L420.1,149z  M416.1,159.8L416.1,159.8l7.3,0.1v-0.1c0-2.8-0.9-5.3-3.6-5.3S416.1,156.9,416.1,159.8L416.1,159.8z"/>
			<path d="M446,155.2c-1.5,0-2.9,0.8-4.4,2.3v19h-6.9v-27h5.7l0.6,3c3.1-2.4,5.3-3.3,7.7-3.3 c4.7,0,7.1,3.4,7.1,8.7v18.8H449v-16.1C448.8,156.5,448,155.2,446,155.2z"/>
			<path d="M477.9,155.4v5.9h-9.3v15.2h-7.1V141h17.2v5.9h-10.2v8.4h9.3v0.2h0.1V155.4z"/>
			<path d="M489.8,176.9c-5.4,0-8.3-5.5-8.3-13.7c0-8.7,3.6-14.2,9.1-14.2c2.1,0,4,1,6.8,3.3l0.6-2.9h5.5 v27.1h-5.7l-0.6-2.9C494,176,492.2,176.9,489.8,176.9L489.8,176.9z M488.6,163.1c0,5.3,1.1,7.7,3.4,7.7c1.5,0,3-0.9,4.4-2.3v-11.2 c-1.4-1.4-2.5-2.1-4-2.1C490.1,155.2,488.6,157.5,488.6,163.1L488.6,163.1z"/>
			<path d="M531.1,162.6c0,8.7-3.6,14.2-8.8,14.2c-2.1,0-4-1-6.8-3.1l-0.8,2.9h-5.5v-37.4l6.9-1V152 c2.5-2.1,4.5-2.9,6.8-2.9C528.2,149,531.1,154.5,531.1,162.6L531.1,162.6z M523.8,162.8c0-5.3-1.1-7.7-3.4-7.7 c-1.5,0-3,0.9-4.4,2.3v11.2c1.4,1.4,2.5,2.1,4,2.1C522.4,170.8,523.8,168.4,523.8,162.8L523.8,162.8z"/>
			<path d="M542.3,176.4h-6.9v-27.1h5.7l0.6,3.3c3-3,4.4-3.8,6.2-3.8h1.1v7.2h-1.4c-2.4,0-3.9,0.6-5.3,1.8 L542.3,176.4L542.3,176.4L542.3,176.4z"/>
			<path d="M552.5,145.4v-6.8h6.9v6.8H552.5z M552.5,176.4v-27.1h6.9v27.1H552.5z"/>
			<path d="M575,149c4.7,0,10.1,2.8,10.1,12.2c0,1.4-0.1,2.3-0.1,4h-14.1c0,4,1.1,6,4.5,6 c1.8,0,5.1-0.5,8.3-1.4l1.4,4.8c-3.9,1.6-7.1,2.4-10.6,2.4c-7.3,0-10.7-3.9-10.7-13.8C564,152.8,568.2,149,575,149L575,149z  M570.9,159.8L570.9,159.8l7.3,0.1v-0.1c0-2.8-0.9-5.3-3.6-5.3C572.1,154.5,570.9,156.9,570.9,159.8z"/>
			<path d="M596.5,164v12.5h-6.9v-37.3l6.9-1V160l0,0l5.3-10.6h8.3l-7.5,11.2l8.8,15.7H603L596.5,164 L596.5,164L596.5,164z"/>
		</g>
	</svg>
);

export default LogoFabriek;
