import React, { Component } from "react";

import Panel from "../Panel/Panel";
import Harmonica from "../Harmonica/Harmonica";

import "./Infopage.scss";

class Infopage extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {

		const { page, language } = this.props;

		const { slug, harmonica, voorwaarden, intro, closed, expandables } = page;

		const showIntro = intro ? (
			<Panel
				data={intro[language]}
				status="initial-open"
			/>
		) : null;

		const showVoorwaarden = voorwaarden ? (
			<Panel
				data={voorwaarden[language]}
				status="initial-closed"
			/>
		) : null;

		const showHarmonica = harmonica ? (
			<div className="page-harmonica">
				<Harmonica
					data={harmonica[language]}
					expandables={expandables}
					closed={closed}
					level={0}
				/>
			</div>
		) : null;

		return (
			<div className={"page infopage " + slug}>
				{showIntro}
				{showVoorwaarden}
				{showHarmonica}
			</div>
		);
	}
}

export default Infopage;