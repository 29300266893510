import React from 'react';

import geslachtsorgaanEn from './geslachtsorgaanEn.svg';
import geslachtsorgaanDonkerblauw from './geslachtsorgaanBlauw.svg';

export const menuItems = [
  {
    id: "uitleg",
    name: {
      nl: "Uitleg",
      en: "Explanation"
    }
  },
  {
    id: "info",
    name: {
      nl: "Meer informatie",
      en: "More information"
    }
  },
  {
    id: "begrippen",
    name: {
      nl: "Begrippen",
      en: "Terms"
    }
  },
  {
    id: "colofon",
    name: {
      nl: "Colofon",
      en: "Colophon"
    }
  }
];

export const modalItems = {
  uitleg: {
    nl: {
    title: 'Wat is deze Keuzehulp?',
    text: <div>
        <p>Deze Keuzehulp informeert je over de verschillende opties voor vruchtbaarheidsbehoud. De psycholoog die betrokken is bij jouw traject op de genderpoli kan je verwijzen naar de gynaecoloog. Je bespreekt met de gynaecoloog voor welke behandelingen je in aanmerking komt en wat de verschillende opties inhouden.</p>
        <p>De Keuzehulp vertelt niet wat voor jou de beste keuze is of wat je moet doen, maar is gemaakt om jou en je omgeving te ondersteunen. De Keuzehulp helpt bij het maken van keuzes voor of tegen vruchtbaarheidsbehoud en zo ja, welke behandeling. Je kunt met je arts bespreken welke argumenten voor jou belangrijk zijn en welk resultaat je van de behandeling kunt verwachten. De wetenschap levert nieuwe inzichten en mogelijkheden op, de situatie kan in de toekomst veranderen.</p>
        <p>Deze Keuzehulp is gemaakt in samenwerking met de academische ziekenhuizen VUmc en AMC, met inbreng van ervaringsdeskundigen.</p>
      </div>
    },
  en: {
    title: 'What is the Decision Aid? ',
    text: <div>
        <p>This Decision Aid provides information on the different options for fertility preservation. The psychologist involved in your process at the gender outpatient clinic can refer you to a gynaecologist. You and your gynaecologist discuss what treatments are available to you and what they involve.</p>
        <p>The Decision Aid does not tell you what the best option is or what you should do, but has been developed to support you and the people around you. The Decision Aid helps you to make decisions regarding fertility preservation, and to choose a treatment for fertility preservation if desired. You can discuss with your doctor what arguments are important to you and what results you can expect of a treatment. As science evolves, new insights and possibilities can emerge, so the situation might change in the future. </p>
        <p>This Decision Aid has been developed in cooperation with the VU University Medical Center (VUmc) and Academic Medical Center (AMC) on the basis of input from patients with previous experience with fertility preservation. </p>
      </div>
    }
  },
  info: {
    nl: {
      title: 'Wat moet ik nog meer weten?',
      text: <div>
      <h6>Waar kan ik terecht voor meer informatie?</h6>
      <ul>
        <li>De psycholoog van het genderteam kan je doorverwijzen naar de gynaecoloog. Deze gynaecoloog van het genderteam geeft je de eerste voorlichting en begeleidt je in het maken van een behandelkeuze.</li>
        <li><a href='https://www.vumc.nl/afdelingen/zorgcentrum-voor-gender/' target='_blank' rel='noopener noreferrer'>Kennis- en Zorgcentrum voor Genderdysforie UMC, locatie VUmc</a>
            <p>Bezoekadres: Polikliniek receptie N, De Boelelaan 1118, 1081 HZ Amsterdam</p>
            <p>E-mail:  <a href='mailto:info-genderteam@vumc.nl'>info-genderteam@vumc.nl</a></p>
            <p>Telefoonnummer: 020 444 0542<br />(Bereikbaar op werkdagen van 9.00 tot 16.00 uur.)</p>
        </li>
        <li><a href='https://www.amc.nl/cvv'target='_blank' rel='noopener noreferrer'>Voortplantingsgeneeskunde (onderdeel van verloskunde & gynaecologie), Amsterdam UMC, locatie VUmc</a><br />
            <p>Adres: De Boelelaan 1118, 1081 HZ Amsterdam</p>
            <p>Bezoekadres: polikliniek receptie H</p>
            <p>E-mail: <a href='mailto:admi.polivg@vumc.nl'>admi.polivg@vumc.nl</a></p>
            <p>Telefoonnummer: 020 444 1190</p></li>
        <li><a href='http://nnf-info.nl/' target='_blank' rel='noopener noreferrer'>Nederlands Netwerk Fertiliteitspreservatie</a></li>
        <li><a href='https://www.freya.nl/' target='_blank' rel='noopener noreferrer'>Freya - patiëntenvereniging voor mensen met vruchtbaarheidsproblemen</a></li>
        <li><a href='https://www.transvisie.nl' target='_blank' rel='noopener noreferrer'>Patientenorganisatie Transvisie</a></li>
        <li><a href='https://www.psyq.nl/genderdysforie' target='_blank' rel='noopener noreferrer'>Transvisie Zorg</a></li>
        <li><a href='http://transgenderinfo.be' target='_blank' rel='noopener noreferrer'>Transgender Infopunt (België)</a></li>
        <li><a href='https://www.umcg.nl/NL/Zorg/Volwassenen/zob2/genderdysforie/Paginas/default.aspx' target='_blank' rel='noopener noreferrer'>Genderteam Groningen</a></li>
        <li><a href='https://genderaid.org/nl/masculiniserend/info/fertiliteit-vruchtbaarheid' target='_blank' rel='noopener noreferrer'>Genderaid</a></li>
     </ul>
    </div>
    },
  en: {
    title: 'Where can I find more information?',
      text: <div>
      <ul>
        <li>Reproductive Medicine Amsterdam UMC, location VUmc 
            <p>Visitors’ address: Outpatient Clinic Reception H, De Boelelaan 1118, 1081 HZ Amsterdam</p>
            <p>E-mail:  <a href='mailto:admi.polivg@vumc.nl'>admi.polivg@vumc.nl</a></p>
            <p>Telephone: +31 (0)20 444 1190 </p>
        </li>
        <li>Kennis- en Zorgcentrum voor Genderdysforie Amsterdam UMC, locatie VUmc
            <p>Visitors’ address: Outpatient Clinic Reception N, De Boelelaan 1118, 1081 HZ Amsterdam</p>
            <p>E-mail: <a href='mailto:info-genderteam@vumc.nl'>info-genderteam@vumc.nll</a></p>
            <p>Telephone: +31 (0)20 444 0542</p></li>
        <li><a href='https://www.keuzehulp-operaties-transmannen.nl/ ' target='_blank' rel='noopener noreferrer'>Decision Aid for genital reconstruction surgeries for trans men</a></li>
        <li><a href='nnf-info.nl/' target='_blank' rel='noopener noreferrer'>Nederlands Netwerk Fertiliteitspreservatie </a></li>
        <li><a href='https://www.freya.nl/' target='_blank' rel='noopener noreferrer'>Freya – patient association for people experiencing fertility problems</a></li>
        <li><a href='https://www.transvisie.nl' target='_blank' rel='noopener noreferrer'>Patient organisation Transvisie </a></li>
        <li><a href='https://www.psyq.nl/genderdysforie' target='_blank' rel='noopener noreferrer'>Transvisie Zorg</a></li>
        <li><a href='http://transgenderinfo.be' target='_blank' rel='noopener noreferrer'>Transgender Infopunt (België)</a></li>
        <li><a href='https://www.umcg.nl/NL/Zorg/Volwassenen/zob2/genderdysforie/Paginas/default.aspx ' target='_blank' rel='noopener noreferrer'>Genderteam Groningen</a></li>
        <li><a href='https://genderaid.org/nl/masculiniserend/info/fertiliteit-vruchtbaarheid' target='_blank' rel='noopener noreferrer'>Genderaid</a></li>
     </ul>
    </div>
    }
  },
  begrippen: {
    nl: {
      title: 'Wat betekenen belangrijke begrippen?',
      text:
    <span>
    <img src={geslachtsorgaanDonkerblauw} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
    <dl>
      <dt>Baarmoeder (Uterus)</dt>
      <dd>Een orgaan waarin de zwangerschap groeit.</dd>
      <dt>Complicatie</dt>
      <dd>Een onbedoelde bijwerking of letsel van een medische behandeling.</dd>
      <dt>Eiblaasje (Follikel)</dt>
      <dd>Een soort vochtblaasje in de eierstok waarin de eicel ontwikkelt/groeit/rijpt.</dd>
      <dt>Eicel (Oöcyt)</dt>
      <dd>Vrouwelijke geslachtscel in de eierstok.</dd>
      <dt>Eierstok (Ovarium)</dt>
      <dd>Twee organen aan beide kanten van de baarmoeder waarin eicellen liggen opgeslagen.</dd>
      <dt>Eierstokweefsel (Cortex)</dt>
      <dd>In de buitenste laag van de eierstokken (cortex) liggen eicellen opgeslagen.</dd>
      <dt>Eileider (Tuba)</dt>
      <dd>Buisvormig orgaan waardoor zaadcellen en embryo zich verplaatsen tussen eierstok en baarmoeder.</dd>
      <dt>Embryo</dt>
      <dd>Een prille zwangerschap (de eerste drie maanden).</dd>
      <dt>Hormoonstimulatie</dt>
      <dd>Injecties met vrouwelijke hormonen om de groei van eiblaasjes te stimuleren.</dd>
      <dt>ICSI-behandeling</dt>
      <dd>Een eicel wordt in het laboratorium bevrucht met een zaadcel. De embryo's worden ingevroren en opgeslagen om later eventueel in de baarmoeder te plaatsen.</dd>
      <dt>IVF-behandeling</dt>
      <dd>Een eicel wordt in het laboratorium met zaadcellen samengevoegd waarna een bevruchting plaats kan vinden. De embryo's worden ingevroren en opgeslagen om later eventueel in de baarmoeder te plaatsen.</dd>
      <dt>Kijkoperatie (Laparoscopie)</dt>
      <dd>De dokter opereert via kleine sneetjes in je lichaam.</dd>
      <dt>Punctie</dt>
      <dd>De dokter prikt met een naald door de vagina om eicellen op te zuigen uit de eierstok.</dd>
      <dt>Vaginale echo</dt>
      <dd>De dokter maakt een echo via de vagina.</dd>
      <dt>Vruchtbaarheidsbehoud (Fertiliteitspreservatie)</dt>
      <dd>Het opslaan van geslachtscellen om de kans op een genetisch eigen kind op een later tijdstip te vergroten.</dd>
      <dt>Zaadcel (Semen)</dt>
      <dd>Mannelijke geslachtscel.</dd>
    </dl>
    </span>
      },
      en: {
      title: 'What do important terms mean?',
      text:
    <span>
    <img src={geslachtsorgaanEn} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
    <dl>
      <dt>Womb (Uterus)</dt>
      <dd>The organ where a baby can grow and develop.</dd>
      <dt>Complication</dt>
      <dd>Unwanted side effect or outcome of a medical treatment.</dd>
      <dt>Follicle</dt>
      <dd>A follicle is a small sac inside the ovaries that is filled with fluid, in which an immature egg develops/grows/matures.</dd>
      <dt>Egg cell (Oocyte)</dt>
      <dd>Female cells in the ovaries involved in reproduction.</dd>
      <dt>Ovary</dt>
      <dd>Two organs on each side of the uterus in which egg cells are stored.</dd>
      <dt>Ovarian tissue (Cortex)</dt>
      <dd>The outer layer of the ovaries (cortex), which contains a large number of immature eggs.</dd>
      <dt>Fallopian tube (Uterine tube)</dt>
      <dd>Pair of narrow ducts that transport male sperm and embryos from the ovaries to the uterus.</dd>
      <dt>Embryo</dt>
      <dd>Early-stage pregnancy (first three months).</dd>
      <dt>Hormonal stimulation </dt>
      <dd>Injections with female hormones to promote follicle development and egg maturation.</dd>
      <dt>Intracytoplasmic sperm injection (ICSI)</dt>
      <dd>Laboratory procedure where a sperm cell fertilises an egg. The embryos are frozen and stored for possible re-implantation in the womb.</dd>
      <dt>In vitro fertilisation (IVF) </dt>
      <dd>The joining of an egg and sperm cells in a laboratory dish so that fertilisation can occur. The embryos are frozen and stored for possible re-implantation in the womb. </dd>
      <dt>Keyhole surgery (Laparoscopy)</dt>
      <dd>A surgical procedure that only requires a small incision in your body. </dd>
      <dt>Egg retrieval procedure (Oocyte retrieval)</dt>
      <dd>A procedure in which a doctor removes eggs from the ovaries via the insertion of a needle through the vaginal wall.</dd>
      <dt>Transvaginal ultrasound </dt>
      <dd>The doctor performs an ultrasound scan via the vagina.</dd>
      <dt>Fertility preservation</dt>
      <dd>The process of saving reproductive cells so that you can use them to increase your chances of having your own genetically-related children in the future.</dd>
      <dt>Sperm cell (Semen)</dt>
      <dd>A male reproductive cell.</dd>
    </dl>
    </span>
      }
},
  colofon: {
    nl: {
      title: 'Hoe is deze Keuzehulp tot stand gekomen?',
      text:
    <div>
      <p>Deze Keuzehulp is gemaakt in opdracht van het Vrouw Kind Centrum van het UMCA (VUmc en AMC, Amsterdam).</p>
      <p>De inhoud van deze Keuzehulp is tot stand gekomen tijdens acht denksessies met ervaringsdeskundigen, medisch specialisten en een psycholoog. Wij danken hen allen voor hun denkwerk.</p>
      <p>De Keuzehulp is nog niet verspreid over alle ziekenhuizen in Nederland. Het kan zijn dat je arts de Keuzehulp (nog) niet kent. Je behandelend arts kan bij vragen contact opnemen met het VUmc of AMC.</p>
      <p><a href='https://www.argumentenfabriek.nl/' target='_blank' rel='noopener noreferrer'>De Argumentenfabriek</a> heeft de Keuzehulp ontwikkeld en vormgegeven.</p>
      <p>© 2018 De Argumentenfabriek</p>
    </div>
  },
  en: {
      title: 'How did this Decision Aid come about?',
      text:
      <div>
        <p>This Decision Aid was commissioned by the Department of Reproductive Medicine of the VUmc and the Center for Reproductive Medicine of the AMC.</p>
        <p>The content of the Decision Aid was based on eight brainstorming sessions with patients with previous experience with fertility preservation, medical specialists and a psychologist. We would like to thank them for their input.</p>
        <p>The Decision Aid has not been distributed yet to all hospitals across the Netherlands. It is possible that your doctor has not heard of the Decision Aid yet. Your doctor is welcome to contact the VUmc or AMC with questions.</p>
        <p><a href='https://www.argumentenfabriek.nl/' target='_blank' rel='noopener noreferrer'>De Argumentenfabriek</a> The Argumentenfabriek is responsible for the development and design of the Decision Aid.</p>
        <p>© 2018 De Argumentenfabriek</p>
      </div>
  }
}
}
